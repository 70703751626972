import React, { useState } from "react"
// ES modules
import ReactDOMServer from 'react-dom/server';
import { connect as connectFela } from "react-fela"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "components/layout"
import colors from "theme/colors"
import Container from "components/container"
import TextInput from "components/TextInput"
import fluidValues from "utilities/fluidValues"
import Social from "components/Social"

const SignaturePage = ({ pageContext, data: { site, settings, jobs}, styles, rules }) => {
  const [ name, setName ] = useState('nick Allan')
  const [ role, setRole ] = useState('Strategy & Engagement')
  const [ phone, setPhone ] = useState('+64 226 748 540')
  const [ email, setEmail ] = useState(pageContext.country == 'nz' ? 'nick@hypr.nz' : 'nick@hyprinnovation.io')

  // const [ facebook, setFacebook ] = useState('https://www.facebook.com/hyprnz/')
  // const [ instagram, setInstagram ] = useState('https://www.instagram.com/hypr.nz/')
  // const [ twitter, setTwitter ] = useState('https://twitter.com/HyprNZ')
  // const [ linkedIn, setLinkedIn ] = useState('https://www.linkedin.com/company/hypr-nz')
  const node = settings

  const signature = (
    <div style={rules.signatureContainer()}>
      <img style={rules.image()} src={`${site.siteMetadata.siteUrl}/logo.png`} alt={'HYPR'} width="98" height="43" />
      <p style={rules.name()}>{name}</p>
      <p style={rules.others()}>{role}</p>
      <p style={rules.others()}>{phone}</p>
      <p style={rules.others()}><a style={rules.link()} href={`mailto:${email}`}><span style={rules.link()}>{email}</span></a> | <a style={rules.link()} href={site.siteMetadata.siteUrl}><span style={rules.link()}>{pageContext.country == 'nz' ? 'hypr.nz' : 'hyprinnovation.io'}</span></a></p>

      <table style={rules.list()}>
        <tr>
      {node.socials && node.socials.map((social, index) => {
        return <td style={rules.li()}>
          <div style={rules.social()} width="20" height="20">
            <a href={social.link}><img style={rules.img()} src={`${site.siteMetadata.siteUrl}/${social.name.toLowerCase()}.png`} width="20" height="20" /></a>
          </div>
        </td>
      })}
        </tr>
      </table>
    </div>
  )
  const renderedSignature = ReactDOMServer.renderToStaticMarkup(signature)

  return (
    <Layout
      slug={{current: '/signatures'}}
      pageColor={"#FFFFFF"}
      linkColor={colors.colorHuman200}
      title={"signature"}
      meta={{}}
      settings={settings}
      jobs={jobs}
      country={pageContext.country}
    >
      <Container withContentArea style={{paddingTop: 240, zIndex: 13}}>
          <form noValidate className={styles.form}>
            <TextInput onChange={(e) => setName(e.currentTarget.value) } title={'Name'} name={'name'} value={name} />
            <TextInput onChange={(e) => setRole(e.currentTarget.value) } title={'Role'} name={'role'} value={role} />
            <TextInput onChange={(e) => setPhone(e.currentTarget.value) } title={'Phone'} name={'phone'} value={phone} />
            <TextInput onChange={(e) => setEmail(e.currentTarget.value) } title={'Email'} name={'email'} value={email} />
          </form>
          <div style={{marginBottom: 24}}>
            Copy the signature inside the dotted area with the "--" above and below, then paste the signature into your email client and remove the "--".
          </div>
          <div className={styles.container}>
            ---<br/>
            {signature}
            ---<br/>
          </div>
          <div style={{marginBottom: 24, marginTop: 24}}>
            For Apple Mail, follow instructions <a style={{textDecoration: 'underline'}} href="http://matt.coneybeare.me/how-to-make-an-html-signature-in-apple-mail-for-macos-catalina-10-dot-15/">here</a>
          </div>
          <pre className={styles.code}>
            {renderedSignature}
          </pre>
      </Container>
    </Layout>
  )
}

const styles = () => ({
  code: {
    backgroundColor: colors.colorCanvas200,
    overflowX: 'scroll',
    overflowY: 'hidden',
    padding: 30,
  },
  list: {
    listStyle: 'none',
    display: 'block',
    paddingLeft: 0,
    marginTop: 12,
  },
  li: {
    marginRight: 6,
    marginLeft: 0,
    display: 'inline',
  },
  social: {
    display: 'inline-block',
    width: 20,
    height: 20,
  },
  img: {
    width: '100%',
    height: '100%',
  },
  signatureContainer: {
    position: 'relative',
    display: 'block',
  },
  name: {
    fontFamily: 'Courier New',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 11,
    lineHeight: '16px',
    letterSpacing: '0.015em',
    marginTop: 6,
    marginBottom: 6,
  },
  link: {
    color: colors.colorConsole500,
    textDecoration: 'underline',
  },
  form: {
    ...fluidValues({
      paddingTop: [0, 50],
    }),

    color: colors.colorConsole500,
    borderColor: colors.colorConsole500,
    "& input[type='text']": {
      color: colors.colorConsole500,
      onFocus: {
        color: colors.colorConsole500,
      }
    },
    "& input[type='email']": {
      color: colors.colorConsole500,
      onFocus: {
        color: colors.colorConsole500,
      }
    },
  },
  image: {
    display: 'block',
    height: 43,
    marginBottom: 12,
    marginLeft: 0,
    marginRight: 'auto',
    position: 'relative',
    width: 98,
  },
  others: {
    fontFamily: 'Courier New',
    fontStyle: 'normal',
    fontSize: 11,
    lineHeight: '16px',
    letterSpacing: '0.015em',
    marginBottom: 6,
    marginTop: 6,
  },
  container: {
    border: '2px dotted black',
    padding: 30,
  }
})

export default connectFela(styles)(SignaturePage)

export const query = graphql`
query($country: String!) {
    settings: sanitySettings(countrySet: { eq: $country }) {
        ...Settings
    }

    site {
      siteMetadata {
        siteUrl
      }
    }

    jobs: allSanityJob(filter: { countrySet: { eq: $country } }) {
        edges {
            node {
                ...Jobs
            }
        }
    }
  }
`;
